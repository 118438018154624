@tailwind base;
@tailwind components;
@tailwind utilities;



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  scroll-behavior: smooth;
}

.btn {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  border: 2 #99B500 solid;
  background-color: #99B500;
  color: white;
  border-radius: 50px;
  
}

.btn:hover {
  border: 2 #A47E3B solid;
  background-color: #A47E3B;
  color: white;
  transition: all 300ms ease;
}

.btn-2 {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  border: 2 white solid;
  background-color: white;
  color: #99B500;
  border-radius: 50px;
}

.btn-2:hover {
  border: 2 #fff solid;
  background-color: #A47E3B;
  color: white;
  transition: all 300ms ease;
}

.bg-main-about {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../src/assets/colorfull-trees.jpg);
  width: 100%;
  height: 100%;
}

.bg-about {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../src/assets/green-trees-dark.jpg);
  width: 100%;
  height: 100%;
}

.bg-contact {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../src/assets/one-tree-down-up.jpg);
  width: 100%;
  height: 100%;
}

.bg-services {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../src/assets/rising-sun.jpg);
  width: 100%;
  height: 100%;
}

.hoverAnimation:hover {
  color: #99B500;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 6px;
  transition: all 300ms ease;
}

.text-shadow {
  text-shadow: 2px 2px 2px green;
}


/* Contact Form */

body,
input,
textarea {
  font-family: "Poppins", sans-serif;
}

.container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  width: 100%;
  max-width: 820px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contact-form {
  background-color: #99B500;
  position: relative;
}

.circle {
  border-radius: 50%;
  position: absolute;
}

.contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: #A47E3B;
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

form {
  padding: 2.3rem 2.2rem;
  z-index: 1;
  overflow: hidden;
  position: relative;
}

.title {
  color: #fff;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1;
  margin-bottom: 32px;
}

.input-container {
  position: relative;
  margin: 1rem 0;
  color: white;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 25px;
  transition: 0.3s;
}

.input::placeholder {
  color: white;
}


textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 22px;
  resize: none;
  overflow-y: auto;
}

.btn-contact {
  padding: 0.6rem 1.3rem;
    background-color: #A47E3B ;
    border: 2px solid #fff;
    font-size: 0.95rem;
    font-weight: bold;
    color: white;
    line-height: 1;
    border-radius: 25px;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin: 0;
  }
  
.btn-contact:hover {
    background-color:white ;
    color: #99B500;
  }

  .filter {
    filter: contrast(0);
    filter: brightness(20%);
  }


  .input-container span {
    position: absolute;
    top: 0;
    left: 25px;
    transform: translateY(-50%);
    font-size: 0.8rem;
    padding: 0 0.4rem;
    color: transparent;
    pointer-events: none;
    z-index: 500;
  }

.input-container.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-info .title {
  color: #A47E3B;
}

.text {
  color: #A47E3B;
  margin: 1.5rem 0 2rem 0;
}

.information {
  display: flex;
  color: #A47E3B;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 20px;
}

.icon {
  width: 28px;
  margin-right: 0.7rem;
}

@media (max-width: 850px) {
  .form {
    grid-template-columns: 1fr;
  }

  .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .text {
    margin: 1rem 0 1.5rem 0;
  } 
}

@media (max-width: 480px) {
  .container {
    padding: 1.5rem;
  }

  .contact-info:before {
    display: none;
  }

  form,
  .contact-info {
    padding: 1.7rem 1.6rem;
  }

  .text,
  .information,
  .social-media p {
    font-size: 0.8rem;
  }

  .title {
    font-size: 1.15rem;
  }

  .icon {
    width: 23px;
  }

  .input {
    padding: 0.45rem 1.2rem;
  }

  .btn {
    padding: 0.45rem 1.2rem;
  }
}
